/* eslint-disable default-case */
import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { generateUniqueId } from 'services';

import CreatePointEntryForm from './CreatePointEntryForm/CreatePointEntryForm';
import ActionLink from 'components/elements/molecules/ActionLink/ActionLink';

import './CreatePoint.scss';

class CreatePointComponent extends Component {
  static propTypes = {
    actions: PropTypes.object,
    status: PropTypes.string,
    isExtension: PropTypes.bool,
    user: PropTypes.object,
    userHighlightType: PropTypes.string,
    recentSourceInfo: PropTypes.object,
    presetPointFields: PropTypes.object,
    pointObject: PropTypes.object,
    point: PropTypes.object,
    pointId: PropTypes.string,
    pointUrl: PropTypes.string,
    showAddToCollection: PropTypes.bool,
    collectionId: PropTypes.string,
    sourceText: PropTypes.string,
    sourceImageURL: PropTypes.string,
    hostedImageURL: PropTypes.string,
    imageType: PropTypes.string,
    pdfThruBrowser: PropTypes.bool,
    setCollectionId: PropTypes.func,
    onCreateCollection: PropTypes.func,
    openCreateCollectionModal: PropTypes.func,
    onClose: PropTypes.func,
    onSavePoint: PropTypes.func,
    errorMessage: PropTypes.string,
    showStance: PropTypes.bool,
    additionalAuthors: PropTypes.arrayOf(PropTypes.string),
    setAdditionalAuthors: PropTypes.func.isRequired,
    focusQuote: PropTypes.bool,
    type: PropTypes.oneOf(['DEFAULT', 'LIGHTWEIGHT']),
    placeholder: PropTypes.string,
    focusPointText: PropTypes.bool,
  };

  static defaultProps = {
    focusQuote: false,
  };

  constructor(props) {
    super(props);

    let status = this.props.status;
    if (!status) {
      // first check in the local storage
      // second check from settings
      const pointStatus = localStorage.getItem('averpointPointRecentStatus');

      if (pointStatus) {
        status = pointStatus;
      } else {
        status =
          this.props.user.settings &&
          this.props.user.settings.privacy &&
          this.props.user.settings.privacy.point === 'private'
            ? 'private'
            : 'public';
      }
    }

    let initialPoint = {
      isNew: true,
      text: '',
      type: this.props.status === 'under review' ? 'Opinion' : 'Decide later',
      status,
      authorName: this.props.user.username,
      authorId: this.props.user._id,
      authorHasPhoto:
        this.props.user.photoLicenseTerms &&
        this.props.user.photoLicenseTerms.status == 'accepted',
      sourceText: '',
      sourceURL: '',
      sourceDate: '',
      source: '',
      sourceAuthor: '',
      imageType: this.props.imageType,
      showPhoto: props.isExtension,
      tags: this.getInitialTags(),
      showQuote: false,
      showTags: false,
      sourceImages: [],
      sourceImageActiveIndex: 0,
    };

    if (this.props.isExtension) {
      initialPoint.sourceURL = this.props.pointUrl;
      console.log(`setting initial point to ${this.props.pointUrl}`);

      if (props.hostedImageURL && props.imageType == 'hosted') {
        initialPoint._id = props.pointId;
        initialPoint.imageType = 'hosted';
        initialPoint.photoLicenseTerms = {
          status: 'accepted', // This allows us to display a hosted image in PointImage
          acceptTerms: true, // This tells the server to update license terms
        };

        initialPoint.sourceImages.push(props.hostedImageURL);
      }
    }

    if (
      !this.props.isExtension &&
      !this.props.isExtensionCL &&
      this.props.recentSourceInfo
    ) {
      const sourceInfo = this.props.recentSourceInfo;
      initialPoint = {
        ...initialPoint,
        ...sourceInfo,
        showQuote: true,
      };
    }

    if (this.props.presetPointFields) {
      initialPoint = {
        ...initialPoint,
        ...this.props.presetPointFields,
      };
    }

    this.state = {
      point: initialPoint,
      stance: props.stance,
      errorMessage: '',
      errorMessages: {
        selectCollection: '',
      },
      showPDFThruBrowserMessage: this.props.pdfThruBrowser === true,
      reachedBottom: false,
      isEditingFields: {
        text: true,
        source: false,
        tags: initialPoint.tags.length > 0,
        image: false,
      },
      newCollection: null,
      isSaving: false,
    };
    this.container = createRef();
  }

  // generateUniqueId = async () => {
  //   return new Promise((resolve, reject) => {
  //     axios({
  //       method: 'get',
  //       url: '/api/utility/getUniqueId',
  //     })
  //       .then(result => {
  //         resolve(result.data._id);
  //         console.log('Got unique _id ');
  //         console.log(result.data._id);
  //       })
  //       .catch(result => {
  //         reject();
  //         console.log('Error getting unique _id');
  //         console.log(result);
  //       });
  //   });
  // };

  getInitialTags = () => {
    let tags = [];

    if (localStorage.hasOwnProperty('recentTags')) {
      let value = localStorage.getItem('recentTags');

      if (value) {
        try {
          value = JSON.parse(value);
        } catch (e) {
          console.log('Error trying to get recentTags from local storage');
        }
      }

      tags = value;
    }
    return tags;
  };

  async componentDidMount() {
    // if(this.props.isExtension){
    //   this.setState({
    //     sourceURL: this.props.pointUrl
    //   })
    // }
    const { isExtension } = this.props;

    let point = {
      ...this.state.point,
    };
    let showNoTextMessage = true;

    if (!point._id) point._id = await generateUniqueId();

    if (
      this.props.sourceText ||
      this.props.sourceImageURL ||
      this.props.hostedImageURL
    ) {
      point.sourceText = this.props.sourceText;
      point.sourceImageURL = this.props.sourceImageURL;
      point.hostedImageURL = this.props.hostedImageURL;
      point.showQuote = !!point.sourceText;
      showNoTextMessage = false;
      // const cleanSourceText = _.unescape(this.props.sourceText);
      // this.setState({
      //   point: {
      //     ...this.state.point,
      //     sourceText: this.props.sourceText,
      //     sourceImageURL: this.props.sourceImageURL
      //   }
      // })
    }

    if (isExtension) {
      point.showQuote = true;
    }

    this.setState({
      point,
      showNoTextMessage,
    });

    document.addEventListener('scroll', this.trackScrolling);
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.trackScrolling);
  }

  trackScrolling = () => {
    const wrappedElement = this.container.current;
    if (wrappedElement && this.isBottom(wrappedElement)) {
      this.setState({
        reachedBottom: true,
      });
      // document.removeEventListener('scroll', this.trackScrolling);
    } else {
      this.setState({
        reachedBottom: false,
      });
    }
  };

  isBottom(el) {
    return el.getBoundingClientRect().bottom <= window.innerHeight;
  }

  renderImportPointsButton() {
    const { hideImportFromPortfolio, onImportPointTab } = this.props;
    const { point } = this.state;
    const tmpDiv = document.createElement('div');
    tmpDiv.innerHTML = point.html || point.text || '';
    const text = (tmpDiv.textContent || tmpDiv.innerText || '').trim();

    if (hideImportFromPortfolio || text) {
      return null;
    }

    return (
      <ActionLink linkClassName="import-point-link" onClick={onImportPointTab}>
        Import from Portfolio
      </ActionLink>
    );
  }

  render() {
    let pointStartClassName = 'point-start-component';
    if (this.props.isExtension) pointStartClassName += ' isExtension';

    return (
      <div className={pointStartClassName} ref={this.container}>
        {this.renderEntryForm()}
        <span className="error-message">{this.props.errorMessage}</span>
        {this.renderImportPointsButton()}
      </div>
    );
  }

  renderEntryForm = () => {
    return (
      <div>
        <CreatePointEntryForm
          point={this.state.point}
          user={this.props.user}
          userHighlightType={this.props.userHighlightType}
          onPointFieldChanged={this.onPointFieldChanged}
          errorMessages={this.state.errorMessages}
          errorMessage={this.state.errorMessage}
          isExtension={this.props.isExtension}
          showStance={this.props.showStance}
          stance={this.state.stance}
          onSelectStance={this.onSelectStance}
          showAddToCollection={this.props.showAddToCollection}
          openCreateCollectionModal={this.props.openCreateCollectionModal}
          collectionId={this.props.collectionId}
          setCollectionId={this.setCollectionId}
          onCreateCollection={this.props.onCreateCollection}
          onClose={this.props.onClose}
          isEditing={true}
          isEditingFields={this.state.isEditingFields}
          onEditPointField={this.onEditPointField}
          additionalAuthors={this.props.additionalAuthors}
          setAdditionalAuthors={this.props.setAdditionalAuthors}
          newCollection={this.state.newCollection}
          onNewCollection={this.onNewCollection}
          setNewCollection={this.setNewCollection}
          focusQuote={this.props.focusQuote}
          onSave={this.onSave}
          type={this.props.type}
          isSaving={this.state.isSaving}
          placeholder={this.props.placeholder}
          focusPointText={this.props.focusPointText}
          isExtensionCL={this.props.isExtensionCL}
          submitText={this.props.submitText}
        />
      </div>
    );
  };

  validate() {
    let { point, errorMessages } = this.state;
    errorMessages = { ...errorMessages };
    let errorMessage = '';

    // First check there is at least text or sourceText. Without, there's no Point. Could be just a link, but not currently allowing that.
    const tmpDiv = document.createElement('div');
    tmpDiv.innerHTML = point.html || point.text || '';
    const text = (tmpDiv.textContent || tmpDiv.innerText || '').trim();
    if (!text && !point.sourceText && !point.sourceTitle && !point.sourceURL) {
      this.setState({
        errorMessage:
          'Your Point must include your own text, a quote, a title, or a link.',
      });
      return false;
    }

    // Next check, all the fields meet their individual validations
    // for (const key in errorMessages) {
    //   if (errorMessages.hasOwnProperty(key)) {
    //     // console.log(key + " -> " + p[key]);
    //     if (errorMessages[key] != '') {
    //       this.setState({
    //         errorMessage: 'Please fix the errors and re-submit',
    //       });
    //       return false;
    //     }
    //   }
    // }

    // let valid = true;
    Object.keys(errorMessages).forEach((key) => {
      if (errorMessages[key] !== '') {
        if (key !== 'overall') {
          this.setState({
            errorMessage:
              'Error: ' + key + '. Please fix the error(s) and re-submit',
          });
          return false;
        }
      }
    });

    if (point.type == 'Fact') {
      if (!point.sourceURL) {
        errorMessages.sourceURL = 'Required';
        errorMessage =
          'If your Point is a Fact, you must provide a source URL and source Name so reviewers can verify.';
        this.setState({
          errorMessages,
          errorMessage,
        });
        return false;
        // errorMessage = "Please fix the errors and resubmit.";
      }

      if (!point.sourceName) {
        errorMessages.sourceName = 'Required';
        errorMessage =
          'If your Point is a Fact, you must provide a source name and source URL so reviewers can verify.';
        this.setState({
          errorMessages,
          errorMessage,
        });
        return false;
      }
    } else {
      errorMessages.sourceURL = '';
      errorMessages.sourceName = '';
    }

    if (
      point.sourceText ||
      point.sourceDate ||
      point.sourceAuthor ||
      point.sourceTitle
    ) {
      if (!point.sourceName) {
        errorMessages.sourceName = 'Required';
        errorMessage =
          'If your Point has source fields, you must provide a source name so reviewers can verify.';
        this.setState({
          errorMessages,
          errorMessage,
        });
        return false;
      }
    } else {
      errorMessages.sourceName = '';
    }

    if (this.props.showAddToCollection && !this.props.collectionId) {
      errorMessages.selectCollection = 'Required';
      errorMessage =
        'To keep things organized, please save your Point to a Collection!';
      this.setState({
        errorMessages,
        errorMessage,
      });
      return false;
    }
    errorMessages.selectCollection = '';

    this.setState({
      errorMessages,
      errorMessage: '',
    });

    return true; // It's valid
  }

  // TODO ::  doing setState in each condition block is not good. Let's create a copy in beginning
  //          of the function, manipulate it in conditions and set it to state at end of function.
  onPointFieldChanged = (key, value) => {
    const { point } = this.state;
    const sourceInfoFields = [
      'sourceTitle',
      'sourceAuthor',
      'sourceName',
      'sourceDate',
    ];

    if (key === 'sourceInfo') {
      console.log('Changing sourceInfo from URL');
      const sourceFields = value;
      if (this.state.point.imageType !== 'hosted') {
        sourceFields.imageType = 'source';
      }
      const isEditingImage =
        sourceFields.sourceImageURL && sourceFields.sourceImageURL.length > 0;

      let pointText = point.text;
      const index = pointText.indexOf(point.sourceURL);

      if (index > -1) {
        pointText =
          pointText.slice(0, index) +
          pointText.slice(index + point.sourceURL.length);
      }

      this.setState({
        point: {
          ...point,
          ...sourceFields,
          sourceInfoModified: false,
          showPhoto:
            this.state.point.showPhoto || sourceFields.sourceImages.length > 0,
          // sourceImages: [
          //   ...this.state.point.sourceImages,
          //   ...sourceFields.sourceImages,
          // ],
          sourceImages: sourceFields.sourceImages,
          text: pointText,
        },
        isEditingFields: {
          ...this.state.isEditingFields,
          image: isEditingImage,
        },
      });
      this.state.errorMessages.sourceError = '';
      this.validatePointField(key, value);
    } else if (sourceInfoFields.indexOf(key) !== -1) {
      console.log('Manually changing source info');
      this.setState({
        point: {
          ...this.state.point,
          [key]: value,
          sourceInfoModified: true,
        },
      });
      this.validatePointField(key, value);
    } else if (key === 'hostedImage') {
      const hostedImageFields = value;
      const imageExists = hostedImageFields && hostedImageFields.acceptTerms;

      this.setState({
        point: {
          ...this.state.point,
          ...hostedImageFields,
          sourceImages: [
            ...this.state.point.sourceImages,
            hostedImageFields.hostedImageURL,
          ],
          sourceImageURL: hostedImageFields.hostedImageURL,
          sourceImageActiveIndex: this.state.point.sourceImages.length,
        },
        isEditingFields: {
          ...this.state.isEditingFields,
          image: !imageExists,
        },
      });
    } else if (key === 'sourceText') {
      this.setState({
        point: {
          ...this.state.point,
          sourceText: value,
          sourceTextModified: true,
        },
      });
    } else if (key === 'sourceImages') {
      this.setState({
        point: {
          ...this.state.point,
          sourceImages: value,
          sourceImageURL: value[0],
          sourceImageActiveIndex: 0,
        },
      });
    } else if (key === 'sourceImageURL') {
      this.setState({
        point: {
          ...this.state.point,
          sourceImageURL: value,
          sourceImageActiveIndex: this.state.point.sourceImages.indexOf(value),
        },
      });
    } else {
      if (key === 'showPhoto' && this.state.isEditingFields['image'] != value) {
        //If showPhoto changed in value, change the +Image in PointEditControls
        this.onEditPointField('image');
      }

      const point = { ...this.state.point, [key]: value };

      // check text has url. update source with url if exists.
      if (key === 'text') {
        const pointText = new DOMParser().parseFromString(value, 'text/html')
          .documentElement.textContent;
        // const matches = pointText.match(/(https?:\/\/[^\s]+)/g);
        const matches = pointText.match(
          /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi,
        );

        if (matches && matches.length) {
          // if (point.sourceURL) {
          //   this.state.errorMessages.sourceError = '1 source per point';
          // } else if (!point.sourceURL) {
          point.sourceURL = matches[0];
          // clear text on url enter
          point.text = '<p></p>';
          // }
        } else {
          this.state.errorMessages.sourceError = '';
        }
      } else if (key === 'sourceURL') {
        point.sourceURL = value;
      } else if (key === 'sourceError') {
        this.state.errorMessages.sourceError = value;
      }

      this.setState({ point });

      this.validatePointField(key, value);
    }
  };

  onEditPointField = (key) => {
    const existingFields = this.state.isEditingFields;
    const newFields = {
      ...existingFields,
      [key]: !existingFields[key],
    };

    this.setState({
      isEditingFields: newFields,
    });
  };

  validatePointField(key, value) {
    const { errorMessages } = this.state;
    switch (key) {
      case 'text':
        if (value.length > 1000) {
          errorMessages.pointText = 'Max 1000 characters';
        } else {
          errorMessages.pointText = '';
        }
        break;
      case 'sourceTitle':
        if (value.length > 200) {
          errorMessages.sourceTitle = 'Max 200 characters';
        } else {
          errorMessages.sourceTitle = '';
        }
        break;
      case 'sourceAuthor':
        if (value.length > 200) {
          errorMessages.sourceAuthor = 'Max 200 characters';
        } else {
          errorMessages.sourceAuthor = '';
        }
        break;
      case 'sourceName':
        if (value.length > 100) {
          errorMessages.sourceName = 'Max 100 characters';
        } else {
          errorMessages.sourceName = '';
        }
        break;
      case 'sourceDate':
        let validDate = false;
        let formattedDate = '';

        if (!value) {
          validDate = true; // Date can be empty
        } else if (moment(value, 'YYYY', true).isValid()) {
          validDate = true;
          formattedDate = value;
        } else if (moment(value, ['M-YYYY', 'M/YYYY'], true).isValid()) {
          validDate = true;
          formattedDate = moment(value, ['M-YYYY', 'M/YYYY'], true).format(
            'MMMM YYYY',
          );
        } else if (moment(value, ['M-D-YYYY', 'M/D/YYYY'], true).isValid()) {
          validDate = true;
          formattedDate = moment(value).format('MMMM D YYYY');
        }

        if (!validDate) {
          errorMessages.sourceDate = "Invalid - click '?' for details";
        } else {
          errorMessages.sourceDate = '';
          this.setState({
            point: {
              ...this.state.point,
              sourceDate: value,
              sourceDateFormatted: formattedDate,
            },
          });
        }
        break;
      case 'type':
        if (value == 'Opinion') {
          if (errorMessages.sourceURL || errorMessages.sourceName) {
            errorMessages.sourceURL = '';
            errorMessages.sourceName = '';
          }
        }
        break;
    }

    this.setState({
      errorMessages,
    });
  }

  onSave = async () => {
    let { point, stance } = this.state;
    const { type } = this.props;

    const valid = this.validate();

    if (valid) {
      // make a copy and remove hidden fields
      point = { ...point };

      if (!point.showQuote) {
        point = {
          ...point,
          sourceText: '',
          sourceURL: '',
          sourceName: '',
          sourceTitle: '',
          sourceAuthor: '',
          sourceDate: '',
          sourceDateFormatted: '',
          sourceTextModified: false,
          sourceInfoModified: false,
          source: '',
        };
      }

      if (!point.showTags) {
        point.tags = [];
      }

      if (!point.showPhoto) {
        point.sourceImageURL = '';
        point.hostedImageURL = '';
      }

      this.setState({
        isSaving: true,
      });
      await this.props.onSavePoint(point, stance);
      this.setState({
        isSaving: false,
      });

      // save point status to localstorage for future use
      localStorage.setItem('averpointPointRecentStatus', point.status);

      if (type === 'LIGHTWEIGHT') {
        this.setState({
          point: {
            ...point,
            _id: await generateUniqueId(),
            text: '',
            sourceText: '',
            sourceURL: '',
            sourceName: '',
            sourceTitle: '',
            sourceAuthor: '',
            sourceDate: '',
            sourceDateFormatted: '',
            sourceTextModified: false,
            sourceInfoModified: false,
            sourceLogoURL: '',
            source: '',
            showQuote: false,
            showTags: false,
            showPhoto: false,
            sourceImageActiveIndex: 0,
            sourceImages: [],
            tags: [],
          },
          errorMessages: {},
        });
      }
    }
  };

  onSelectStance = (stance) => {
    this.setState({
      stance,
    });
  };

  setCollectionId = (collectionId) => {
    if (collectionId) {
      const { errorMessages } = this.state;
      errorMessages.selectCollection = '';

      this.setState({
        errorMessages,
        errorMessage: '',
      });
    }
    // Set it to a collectionId or null (if it's clearing the selection)
    this.props.setCollectionId(collectionId);
  };

  onNewCollection = () => {
    this.setState({
      newCollection: {
        text: '',
        status: 'private',
      },
    });
  };

  setNewCollection = (newCollection) => {
    this.setState({ newCollection });
  };
}

export default CreatePointComponent;
